






















import { defineComponent, reactive, ref } from '@/plugins/composition';
import { rules } from '@/composables/useValidation/validations';
import { useLoader } from '@/composables/useLoader';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import { useApi } from '@/composables/useApi';
import { routeNames } from '@/router/routeNames';

export default defineComponent({
  components: { MemberPage, BaseButton },
  name: 'ForgotPasswordPage',
  setup() {
    const { loading, withLoader } = useLoader();
    const valid = ref(false);
    const success = ref(false);
    const { post } = useApi();

    const formData = reactive({
      email: '',
    });

    const submit = async () =>
      withLoader(async () => {
        const response = await post('/forgot-password', formData).finally(() => {
          success.value = true;
        });
      });

    return {
      rules,
      formData,
      valid,
      success,
      submit,
      loading,
      routeNames,
    };
  },
});
